<template>
  <div class="minha-conta" :style="seguros.length == 0 ? 'height:100vh' : ''">
    <header class="bg-dark py-2">
      <div class="container">
        <div class="row">
          <div class="col">
            <router-link to="/">
              <img src="/img/logo.svg" width="150" height="34" />
            </router-link>
            <span class="badge badge-success ml-2">admin</span>
          </div>
          <div class="col text-right">
            <SideNav />
          </div>
        </div>
      </div>
    </header>
    <div class="bg-gradient">
      <div class="container text-center pt-3">
        <h3>Segurados</h3>
        <div class="h-line" style="width:142px;"></div>
      </div>
    </div>

    <div class="container mt-5 pb-5">
      <b-card class="mb-5 my-card">
        <div class="row my-3 mx-1">
          <div class="col-md-4 mb-2">
            <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text" id="basic-addon1">
                  Nome
                </span>
              </div>
              <input type="text" class="form-control" v-model="nome" />
            </div>
          </div>
          <div class="col-md-4 mb-2">
            <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text" id="basic-addon1">
                  Telefone
                </span>
              </div>
              <input type="text" class="form-control" v-mask="['(##) ####-####', '(##) #####-####']"
                v-model="telefone" />
            </div>
          </div>
          <div class="col-md-4 mb-2">
            <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text" id="basic-addon1">
                  CPF
                </span>
              </div>
              <input type="text" class="form-control" v-mask="'###.###.###-##'" v-model="cpf" />
            </div>
          </div>
          <div class="col-md-4">
            <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text" id="basic-addon1">
                  Email
                </span>
              </div>
              <input type="text" class="form-control" v-model="email" />
            </div>
          </div>
          <div class="col-md-4 ">
            <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text" id="basic-addon1">Status</span>
              </div>

              <b-form-select v-model="statusSelecionado">
                <option :value="null">Todos </option>
                <option :value="config.SITUACAO_CANCELADA">Cancelada </option>
                <option :value="config.SITUACAO_REPROVADA">Reprovada </option>
                <option :value="config.SITUACAO_PENDENTE">Pendente </option>
                <option :value="config.SITUACAO_ENVIADA">Enviada </option>
                <option :value="config.SITUACAO_APROVADA">Aprovada </option>
              </b-form-select>
            </div>
          </div>
          <div class="col-md-4 ">
            <button type="submit" class="btn btn-success btn-block " @click.prevent="buscarSegurados()"
              :disabled="loading">
              <span v-if="!loading">Buscar seguro</span>
              <span v-if="loading">Por favor aguarde...</span>
            </button>
          </div>
        </div>
      </b-card>

      <div class="row">
        <div class="col-lg-12 order-lg-2">
          <div class="text-center" v-if="seguros.length == 0 && !loading">
            <p>Ainda não há seguros para essa pesquisa.</p>
          </div>
          <div v-if="!loading">
            <b-card no-body class="mb-3 my-card" v-for="(seguro, index) in seguros" :key="'seguro' + index">
              <div class="row " style="padding-top:20px;padding-left:20px">
                <div class="col-4">
                  <p><b>Nome:</b> {{ seguro.nomeCliente }}</p>
                  <p><b>Email:</b> {{ seguro.emailCliente }}</p>
                </div>
                <div class="col-4">
                  <p><b>Telefone:</b> {{ seguro.telefoneCliente }}</p>
                  <p><b>CPF: </b> {{ seguro.cpfCliente }}</p>
                </div>
                <div class="col-4">
                  <p><b>Marca:</b> {{ seguro.marcaBike }}</p>
                  <p><b>Modelo:</b> {{ seguro.modeloBike }}</p>
                </div>
              </div>
              <hr />
              <b-collapse visible :id="'seguro' + index" role="tabpanel">
                <b-card-body>
                  <div class="row">
                    <div class="col-4">
                      <div><b> Data criado</b></div>
                      {{ formatacaoData.formatarDataLocal(seguro.dataCriado) }}
                    </div>
                    <div class="col-4">
                      <b> Situação seguro </b>
                      <SituacaoSeguro :situacao="seguro.situacaoSeguro" />
                    </div>
                    <div class="col-4">
                      <b>Informações Inspec</b>
                      <StatusInspec :item="seguro" />
                    </div>
                    <div class="col-4  mb-3">
                      <b>Seguradora</b>
                      <div>
                        <div v-if="seguro.seguroEssor">
                          <span class="badge badge-success">Essor</span>
                          <input type="text" class="d-none" :value="seguro.id" :id="seguro.id" />
                          <a href="javascript:void(0);" @click="copy(seguro.id)">
                            copiar id</a>
                        </div>
                        <div v-else>
                          <span class="badge badge-warning">Kakau</span>
                        </div>
                      </div>
                    </div>
                    <div class="col-4" v-if="seguro.situacaoSeguro != config.SITUACAO_ENCERRADO
                    ">
                      <b> Ações</b>
                      <div v-if="seguro.situacaoSeguro == config.SITUACAO_PENDENTE
                      ">
                        <div v-if="seguro.followUp">
                          <a href="javascript:void(0)" @click="ajustarFollowUp(seguro.id, seguro.followUp)">
                            Remover notificação de FollowUp
                          </a>
                        </div>
                        <div v-else>
                          <a href="javascript:void(0)" v-if="seguro.situacaoSeguro == config.SITUACAO_PENDENTE
                          " @click="ajustarFollowUp(seguro.id, seguro.followUp)">
                            Ativar notificação de FollowUp
                          </a>
                        </div>
                      </div>
                      <div>
                        <a href="javascript:void(0)" @click="abrirModalNotificacao(seguro)">
                          Desativar notificações de cobrança
                        </a>
                      </div>
                      <div>
                        <a href="javascript:void(0)" @click="abrirModalLiberarEmail">
                          Liberar e-mail para cotação
                        </a>
                      </div>
                    </div>
                    <div v-if="seguro.seguroEssor" class="col-4">
                      <b>Histórico</b>
                      <div>
                        <a href="javascript:void(0)" @click="abrirModalHistorico(seguro)">
                          Cobranças
                        </a>
                      </div>
                      <div class="my-4" v-if="seguro.linkPagamento">
                        <strong>Link de pagamento</strong>
                        <div class="mt-2" style="display:flex">
                          <div>
                            <b-form-input :value="seguro.linkPagamento" :id="seguro.linkPagamento" type="text"
                              readonly></b-form-input>
                          </div>
                          <div>
                            <b-button @click.prevent="copiarLink(seguro.linkPagamento)" variant="primary">Copiar
                              link</b-button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div v-if="(seguro.situacaoSeguro == config.SITUACAO_APROVADA ||
                      seguro.situacaoSeguro == config.SITUACAO_ENVIADA) &&
                      seguro.seguroEssor
                    " class="col-4">
                      <b>Desconto</b> <br />
                      <small v-if="seguro.cupom">
                        ** Esse seguro tem o cupom:
                        <span class=" ml-1 badge badge-pill badge-danger">
                          {{ seguro.cupom }}</span></small>
                      <a v-if="!seguro.parcelamento" href="javascript:void(0)" @click="abrirModalDesconto(seguro)">
                        Aplicar desconto
                      </a>
                    </div>
                    <div class="col-4">
                      <strong>Informações</strong>
                      <div>
                        <a href="javascript:void(0)" @click="abrirModalDadosSeguro(seguro)">
                          Seguro<span class=" ml-1 badge badge-pill badge-warning">{{ seguro.tipoAssinatura }}</span>
                        </a>
                      </div>
                    </div>
                    <div class="col-4 " v-if="seguro.situacaoSeguro == config.SITUACAO_APROVADA">
                      <strong>Editar</strong>
                      <div>
                        <a href="javascript:void(0)" @click="abrirModalEditarSeguro(seguro)">
                          Dados do Seguro
                        </a>
                      </div>
                    </div>
                    <div class="col-4 " v-if="seguro.situacaoSeguro != config.SITUACAO_ENCERRADO && seguro.situacaoSeguro != config.SITUACAO_PENDENTE && seguro.situacaoSeguro != config.SITUACAO_CANCELADA &&
                      (seguro.inspecStatus ==
                        config.STATUS_INSPEC_APROVADO ||
                        seguro.inspecStatus ==
                        config.STATUS_INSPEC_APROVADO ||
                        seguro.inspecStatus ==
                        config.STATUS_INSPEC_VALOR_ALTERADO ||
                        seguro.idSubscriptionKakau != null ||
                        seguro.inspecStatus ==
                        config.STATUS_INSPEC_AGUARDANDO ||
                        ((seguro.inspecStatus ==
                          config.STATUS_INSPEC_AGUARDANDO || seguro.inspecStatus == null) && seguro.inspecStatus == null && seguro.cobrancasSeguro != null && seguro.cobrancasSeguro.length > 0) ||
                        ((seguro.inspecStatus ==
                          config.STATUS_INSPEC_AGUARDANDO || seguro.inspecStatus == null) && seguro.parcelamento) || seguro.situacaoSeguro == config.SITUACAO_PRE_ENVIADA)
                    ">
                      <strong>Cancelar</strong>
                      <div>
                        <a href="javascript:void(0)" class="text-danger" @click="abrirModalCancelarSeguro(seguro)">
                          Cancelar Seguro
                        </a>
                      </div>
                    </div>
                  </div>
                </b-card-body>
              </b-collapse>
            </b-card>
          </div>

          <div v-else class="text-center">
            <lottie-player src="/anim/mountain_bike.json" background="transparent" speed="1.7"
              style="width: 72px; height: 72px; margin:0 auto;" autoplay loop class="mt-3"></lottie-player>
            <div>Carregando, por favor aguarde...</div>
          </div>
        </div>
      </div>
    </div>

    <ModalAlterarDados :seguro="seguro" :showModalAlterar="showModalAlterar" :nascimento="nascimento"
      @hide="hideModalEditarSeguro" />

    <ModalHistorico :seguro="seguro" />

    <ModalDesconto :seguro="seguro" :showModalDesconto="showModalDesconto" @hide="hideModalDesconto" />

    <ModalNotificacao :seguro="seguro" :showModalNotificacao="showModalNotificacao" @hide="hideModalNotificacao" />

    <ModalDadosSegurado :seguro="seguro" :showModal="showModal" @hide="hideModalDadosSeguro" :dataSeguro="dataSeguro" />

    <ModalCancelarSeguro :idUsuarioAdm="idUsuarioAdm" :idSeguro="idSeguro"
      :showModalCancelarSeguro="showModalCancelarSeguro" @hide="hideModalCancelarSeguro" />

    <ModalLiberarEmail :show="showModalEmail" @hide="hideModalLiberarEmail" />
  </div>
</template>

<script>
import SideNav from "../../components/Admin/SideNav";
import StatusInspec from "../../components/Admin/StatusInspec";
import SituacaoSeguro from "../../components/Admin/SituacaoSeguro";
import ModalAlterarDados from "../../components/Admin/ModalAlterarDados";
import ModalHistorico from "../../components/Admin/ModalHistorico";
import ModalDesconto from "../../components/Admin/ModalDesconto";
import ModalNotificacao from "../../components/Admin/ModalNotificacao";
import ModalCancelarSeguro from "../../components/Usuario/ModalCancelarSeguro";
import ModalDadosSegurado from "../../components/Admin/ModalDadosSegurado";
import ModalLiberarEmail from "../../components/Admin/ModalLiberarEmail";
import moment from "moment";
import config from "../../config";
import auth from "../../auth";
import Swal from "sweetalert2";
import axios from "axios";
import captureError from "../../helpers/captureError";
import formatacaoMoeda from "../../helpers/formatacaoMoeda";
import formatacaoData from "../../helpers/formatacaoData";
import helper from "../../helpers/helper";
import formatacaoTelefone from "../../helpers/formatacaoTelefone";

export default {
  components: {
    SideNav,
    SituacaoSeguro,
    StatusInspec,
    ModalAlterarDados,
    ModalHistorico,
    ModalDadosSegurado,
    ModalDesconto,
    ModalNotificacao,
    ModalCancelarSeguro,
    ModalLiberarEmail,
  },
  data() {
    return {
      statusSelecionado: null,
      // statusFaturaSelecionado: null,
      config,
      loading: false,
      moment: moment,
      seguros: [],
      // filtrados: [],
      nome: null,
      email: null,
      telefone: null,
      cpf: null,
      idUsuarioAdm: undefined,
      formatacaoMoeda,
      formatacaoData,
      formatacaoTelefone,
      helper,
      seguro: undefined,
      idSeguro: undefined,
      showModal: false,
      dataSeguro: null,
      showModalAlterar: false,
      nascimento: "",
      showModalDesconto: false,
      showModalNotificacao: false,
      showModalCancelarSeguro: false,
      showModalEmail: false,
    };
  },
  created() {
    var userInfo = auth.getUserInfo();
    if (userInfo && userInfo.cargo) {
      this.idUsuarioAdm = userInfo.id;
    } else {
      this.$router.replace("/login");
    }
  },
  methods: {
    copiarLink(id) {
      var copyText = document.getElementById(`${id}`);
      copyText.select();
      copyText.setSelectionRange(0, 99999);
      document.execCommand("copy");

      Swal.fire({
        position: "center",
        icon: "success",
        title: "O link de pagamento foi copiado!",
        text: `${copyText.value}`,
        showConfirmButton: true,
        timer: 20000,
      });
    },
    copy(id) {
      var copyText = document.getElementById(id);

      copyText.select();
      copyText.setSelectionRange(0, 99999);

      navigator.clipboard.writeText(copyText.value);

      this.$toast.success("Copiado");
    },
    abrirModalEditarSeguro(seguro) {
      this.seguro = seguro;

      if (this.seguro.nascimentoCliente) {
        this.nascimento = moment(
          this.seguro.nascimentoCliente.split("Z")[0]
        ).format("DD/MM/YYYY");
      }
      this.showModalAlterar = true;
    },
    abrirModalCancelarSeguro(seguro) {
      this.idSeguro = seguro.id;
      this.showModalCancelarSeguro = true;
    },
    hideModalCancelarSeguro() {
      this.showModalCancelarSeguro = false;
    },
    hideModalEditarSeguro() {
      this.showModalAlterar = false;
      this.dataSeguro = null;
    },
    abrirModalHistorico(seguro) {
      this.seguro = seguro;
      this.$bvModal.show("historico-cobranca");
    },
    abrirModalDesconto(seguro) {
      this.seguro = seguro;
      this.showModalDesconto = true;
    },
    abrirModalNotificacao(seguro) {
      this.seguro = seguro;
      this.showModalNotificacao = true;
    },
    abrirModalLiberarEmail() {
      this.showModalEmail = true;
    },
    hideModalLiberarEmail() {
      this.showModalEmail = false;
    },
    hideModalDesconto(status) {
      this.showModalDesconto = false;
      if (status.modificou) {
        this.buscarSegurados();
      }
    },
    hideModalNotificacao(status) {
      this.showModalNotificacao = false;
      if (status.modificou) {
        this.buscarSegurados();
      }
    },
    abrirModalDadosSeguro(seguro) {
      this.seguro = seguro;
      if (seguro.cobrancasSeguro.length > 0) {
        let lasts = [...this.seguro.cobrancasSeguro].pop();
        this.dataSeguro = lasts.essorDataEmissao;
      }

      this.showModal = true;
    },
    hideModalDadosSeguro() {
      this.showModal = false;
      this.dataSeguro = null;
    },

    async buscarSegurados() {
      if (!this.email && !this.nome && !this.telefone && !this.cpf) {
        Swal.fire({
          icon: "warning",
          text: "Informe ao menos um filtro!",
        });
        return;
      }

      this.loading = true;

      var data = {
        email: this.email,
        nome: this.nome,
        telefone: this.telefone,
        cpf: this.cpf,
        situacaoSeguro: this.statusSelecionado,
      };

      try {
        var response = await axios.post(
          `${config.API_URLV2}/seguro/buscar`,
          data
        );
        this.seguros = response.data;
        this.loading = false;
      } catch (error) {
        this.loading = false;
        captureError(error);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text:
            "Não foi possível consultar os segurados. Tente novamente mais tarde ou contate o suporte.",
        });
      }
    },
    async ajustarFollowUp(id, followUp) {
      try {
        Swal.fire({
          text: `Tem certeza que deseja ${followUp ? "remover" : "ativar"}?`,
          showConfirmButton: true,
          showCancelButton: true,
          confirmButtonText: `${followUp ? "Remover" : "Ativar"}`,
          cancelButtonText: `Ainda não`,
        }).then(async (result) => {
          if (result.value) {
            this.loading = true;
            await axios.post(
              `${config.API_URLV2}/seguro/deletarFollowUp/${id}`
            );
            if (!followUp) {
              this.$toast.success(`follow Up ativado com sucesso`);
            } else {
              this.$toast.warning(`follow Up removido com sucesso`);
            }

            this.buscarSegurados();
          } else {
            this.loading = false;
          }
        });
      } catch (error) {
        this.loading = false;
        captureError(error);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Não foi possivel remover o segurado do follow Up",
        });
      }
    },
  },
};
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');

.my-card {
  border-radius: 12px;
  box-shadow: 0 6px 10px -4px rgba(0, 0, 0, .15);
  background-color: #fff;
  color: #252422;
  margin-bottom: 20px;
  position: relative;
  border: 0;
}

.minha-conta {
  font-family: "Montserrat";
  background-color: #f4f3ef;
  transition: all .5s cubic-bezier(.685, .0473, .346, 1);
}

.bg-gradient {
  background: rgb(0, 0, 0);
  background: linear-gradient(180deg,
      rgba(0, 0, 0, 0.14609593837535018) 0%,
      rgba(255, 255, 255, 0) 70%);
}

.icone-principal {
  font-size: 43px;
  color: #28a745;
  background: -webkit-linear-gradient(#76b566, #28a745);
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.not-collapsed .when-closed,
.collapsed .when-open {
  display: none;
}
</style>
